.App {
  text-align: center;
  margin: 1rem;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  _background-color: #282c34;
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(0,0,0);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

div.links {
  text-align: left;
  text-transform: uppercase;
}

div.frame img {
    /* max-width: 100%; */
    width: auto;
    max-height: 90vh;
    max-width: 100%;
}

.video-react, .react-player {
    margin: auto;
    /* width: 100%; */
}

header h1 {
  font-size: 2rem;
  /* text-align: left; */
  text-transform: uppercase;;
}

header h1 span {
background: rgb(0,0,0);
color: rgb(255,255,255);
padding: 0.5rem;
}

header h1 span a {
  text-decoration: none;
  color: rgb(255,255,255);
}

h2 a:link {
  color: rgb(240,60,200);
  /* text-decoration: none; */
}

nav {
  font-size: 2rem;
}

nav a {
  color: rgb(240,60,200);
}

footer {
  background: rgb(240,240,240);
  padding: 1rem;
  text-transform: uppercase;
}

div.contentItem {
  margin-bottom: 20rem;
}

img.icon.heart {
  width: 8rem;

   /* vertically centers */
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

a.purchase {
  font-size: 2rem;
  text-decoration: none;
  background: rgb(0,0,0);
  color: rgb(255,200,255);
  padding: 0.4rem;
}